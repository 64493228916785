import React, { useEffect, useRef, useState } from 'react';
import { ColorPicker } from './ColorPicker';
import { BrushSizeSelector } from './BrushSizeSelector';
import { ToolBar } from './ToolBar';
import { StickerPanel } from './StickerPanel';
import styles from './DrawingBoard.module.css';
import { isMobile } from 'react-device-detect'; // 需要安装: npm install react-device-detect
import { BsPencil, BsEraser } from 'react-icons/bs';
import ReactDOMServer from 'react-dom/server';

const toolCursors = {
    brush: 'url(data:image/svg+xml;base64,' + btoa(ReactDOMServer.renderToStaticMarkup(<BsPencil />)) + ') 8 24, auto',
    eraser: 'url(data:image/svg+xml;base64,' + btoa(ReactDOMServer.renderToStaticMarkup(<BsEraser />)) + ') 8 24, auto',
    default: 'auto'
};

const DrawingBoard = () => {
    const canvasRef = useRef(null);
    const ctxRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [currentTool, setCurrentTool] = useState('brush');
    const [color, setColor] = useState('#000000');
    const [lineWidth, setLineWidth] = useState(5);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [panels, setPanels] = useState({
        colorPicker: false,
        brushSize: false,
        stickers: false
    });

    // 初始化画布
    useEffect(() => {

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        // 根据容器大小设置画布大小
        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight; // 全屏高度
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.strokeStyle = color;
            ctx.lineWidth = lineWidth;
            ctx.lineCap = 'round';
            ctx.lineJoin = 'round';
            ctxRef.current = ctx;

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            // 保存初始状态
            const newEntry = {
                type: 'init',
                data: imageData
            };
            saveToHistory(newEntry);
        };
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        // Add touch event listeners with non-passive option
        const options = { passive: false }; // Set passive to false
        canvas.addEventListener('touchstart', startDrawing, options);
        canvas.addEventListener('touchmove', draw, options);
        canvas.addEventListener('touchend', stopDrawing, options);
        canvas.addEventListener('touchcancel', stopDrawing, options);
        // 绑定鼠标事件
        // canvas.addEventListener('mousedown', handleMouseDown, options);
        // canvas.addEventListener('mousemove', handleMouseMove, options);
        // canvas.addEventListener('mouseup', handleMouseUp, options);
        // canvas.addEventListener('mouseout', handleMouseUp, options); // 处理鼠标移出画布的情况
        canvas.addEventListener('mousedown', startDrawing, options);
        canvas.addEventListener('mousemove', draw, options);
        canvas.addEventListener('mouseup', stopDrawing, options);
        canvas.addEventListener('mouseout', stopDrawing, options); // 处理鼠标移出画布的情况
        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener('resize', resizeCanvas);
            // canvas.removeEventListener('dragover', handleDragOver);
            // canvas.removeEventListener('drop', handleDrop);
            canvas.removeEventListener('touchstart', startDrawing, options);
            canvas.removeEventListener('touchmove', draw, options);
            canvas.removeEventListener('touchend', stopDrawing, options);
            canvas.removeEventListener('touchcancel', stopDrawing, options);
            // canvas.removeEventListener('mousedown', handleMouseDown);
            // canvas.removeEventListener('mousemove', handleMouseMove);
            // canvas.removeEventListener('mouseup', handleMouseUp);
            // canvas.removeEventListener('mouseout', handleMouseUp);
            canvas.removeEventListener('mousedown', startDrawing);
            canvas.removeEventListener('mousemove', draw);
            canvas.removeEventListener('mouseup', stopDrawing);
            canvas.removeEventListener('mouseout', stopDrawing);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // 确保所有依赖都包含在这里


    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            // 绑定拖拽事件
            canvas.addEventListener('dragover', handleDragOver);
            canvas.addEventListener('drop', handleDrop);
            return () => {
                canvas.removeEventListener('dragover', handleDragOver);
                canvas.removeEventListener('drop', handleDrop);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyIndex]);

    // 更新画笔属性
    useEffect(() => {
        // console.log("useEffect resizeCanvas")
        if (ctxRef.current) {
            ctxRef.current.strokeStyle = currentTool === 'eraser' ? '#ffffff' : color;
            ctxRef.current.lineWidth = lineWidth;
        }
    }, [color, lineWidth, currentTool]);

    // 设置 cursor 属性
    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.style.cursor = toolCursors[currentTool] || toolCursors.default;
        }
    }, [currentTool]);

    const handleDragOver = (e) => {
        // console.log("historyIndex", historyIndex)
        e.preventDefault();
    };

    const handleDrop = (e) => {
        // console.log("historyIndex", historyIndex)
        e.preventDefault();

        const stickerData = e.dataTransfer.getData('sticker');
        // console.log("Dropped sticker data:", stickerData); // 调试信息
        if (stickerData) {
            const sticker = JSON.parse(stickerData);
            // console.log("Parsed sticker:", sticker); // 调试信息
            addSticker(sticker, e);
        }
    };

    // 保存状态到历史记录
    const saveToHistory = (imageData) => {
        const newHistory = history.slice(0, historyIndex + 1);
        newHistory.push(imageData);
        setHistory(newHistory);
        setHistoryIndex(newHistory.length - 1);
    };


     // 撤销
     const undo = () => {
        if (historyIndex > 0) {
            setHistoryIndex(historyIndex - 1);
            const imageData = history[historyIndex - 1];
            ctxRef.current.putImageData(imageData.data, 0, 0);
        }
    };

    // 重做
    const redo = () => {
        if (historyIndex < history.length - 1) {
            setHistoryIndex(historyIndex + 1);
            const imageData = history[historyIndex + 1];
            ctxRef.current.putImageData(imageData.data, 0, 0);
        }
    };

    // 统一处理触摸和鼠标事件的坐标
    const getCoordinates = (e) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        let x, y;
        if (e.touches && e.touches[0]) {
             // 触摸事件
            x = (e.touches[0].clientX - rect.left) * scaleX;
            y = (e.touches[0].clientY - rect.top) * scaleY;
        } else {
            // 鼠标事件
            x = (e.clientX - rect.left) * scaleX;
            y = (e.clientY - rect.top) * scaleY;
        }

        // 确保坐标在画布范围内
        x = Math.max(0, Math.min(x, canvas.width));
        y = Math.max(0, Math.min(y, canvas.height));
        // console.log("getCoordinates：" , x ,y )

        return { x, y };
    };

    const startDrawing = (e) => {
        // console.log("startDrawing")
        e.preventDefault();
        const { x, y } = getCoordinates(e);
        ctxRef.current.beginPath();
        ctxRef.current.moveTo(x, y);
        setIsDrawing(true);
    };

    // 绘画中
    const draw = (e) => {
        // console.log("draw")
        e.preventDefault();
        if (!isDrawing) return;

        const { x, y } = getCoordinates(e);
        ctxRef.current.lineTo(x, y);
        ctxRef.current.stroke();
    };

    // 结束绘画
    const stopDrawing = () => {
        // console.log("stopDrawing")
        if (isDrawing) {
            ctxRef.current.closePath();
            setIsDrawing(false);
            
            // 保存当前状态到历史记录
            const canvas = canvasRef.current;
            const imageData = ctxRef.current.getImageData(0, 0, canvas.width, canvas.height);
            const newEntry = {
                type: 'draw',
                data: imageData
            };
            saveToHistory(newEntry);
        }
    };

    // 清空画布
    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = ctxRef.current;
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // 保存清空状态到历史记录
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const newEntry = {
            type: 'clearCanvas',
            data: imageData
        };
        saveToHistory(newEntry);
    };

    // 保存画布
    const saveCanvas = () => {
        const link = document.createElement('a');
        link.download = 'drawing.png';
        link.href = canvasRef.current.toDataURL();
        link.click();
    };

    // 切换面板
    const togglePanel = (panelName) => {
        setPanels(prev => {
            // 先关闭所有面板
            const newPanels = {
                colorPicker: false,
                brushSize: false,
                stickers: false
            };

            // 再切换指定面板的状态
            newPanels[panelName] = !prev[panelName];

            return newPanels;
        });
    };

    const addSticker = (stickerData, e) => {
        e.preventDefault(); // 确保阻止默认行为
        const canvas = canvasRef.current;
        const ctx = ctxRef.current;
        const rect = canvas.getBoundingClientRect();
        // 确保 e.clientX 和 e.clientY 是有效的
        const x = e.clientX - rect.left; // 计算贴纸放置的 x 坐标
        const y = e.clientY - rect.top; // 计算贴纸放置的 y 坐标
    
        const img = new Image();
        img.src = stickerData.url;
        img.onload = () => {
            // console.log("Sticker image loaded:", img); // 调试信息
            const element = {
                ...stickerData,
                image: img,
                x: x - stickerData.width / 2, // 使贴纸中心对齐
                y: y - stickerData.height / 2,
                width: stickerData.width,
                height: stickerData.height,
                isResizing: false, // 初始化为不调整大小
                isDragging: false, // 初始化为不拖动
            };
            if (element.image) {
                ctx.drawImage(
                    element.image,
                    element.x,
                    element.y,
                    element.width,
                    element.height
                );
                drawResizeHandles(ctx, element); // 绘制调整手柄
            }
            // 将新贴纸添加到元素数组中
            // console.log("Adding sticker:", newSticker); // 调试信息
            // setElements(prev => [...prev, newSticker]);
            // redrawCanvas(); // 重新绘制画布

            // 保存当前状态到历史记录
            const canvas = canvasRef.current;
            const imageData = ctxRef.current.getImageData(0, 0, canvas.width, canvas.height);
            const newEntry = {
                type: 'sticker',
                data: imageData
            };
            saveToHistory(newEntry);
        };
        console.log("Adding sticker historyIndex:", historyIndex)
    };

    // const handleMouseDown = (e) => {
    //     setIsMouseDown(true); // 设置鼠标按下状态
    //     const { x, y } = getCoordinates(e);
    //     const sticker = elements.find(el =>
    //         // el.type === 'sticker' &&
    //         x >= el.x && x <= el.x + el.width &&
    //         y >= el.y && y <= el.y + el.height
    //     );
    //
    //     if (sticker) {
    //         // 检查是否点击了调整手柄
    //         const isResizing = checkIfResizing(sticker, x, y);
    //         if (isResizing) {
    //             sticker.isResizing = true; // 设置为调整大小状态
    //         } else {
    //             sticker.isDragging = true; // 设置为拖动状态
    //         }
    //         setElements([...elements]); // 更新状态以触发重绘
    //     }
    // };
    //
    // const handleMouseMove = (e) => {
    //     if (!isMouseDown) return; // 如果鼠标没有按下，不执行任何操作
    //     const { x, y } = getCoordinates(e);
    //     const sticker = elements.find(el => el.isDragging || el.isResizing);
    //
    //      // 检查是否悬停在手柄上
    //     const isHoveringResizeHandle = sticker && checkIfResizing(sticker, x, y);
    //     if (isHoveringResizeHandle) {
    //         canvasRef.current.style.cursor = 'nwse-resize'; // 设置光标为调整大小
    //     } else {
    //         canvasRef.current.style.cursor = 'default'; // 恢复默认光标
    //     }
    //
    //     if (sticker) {
    //         if (sticker.isResizing) {
    //             // 调整贴纸大小
    //             sticker.width = Math.max(10, x - sticker.x); // 确保宽度不小于10
    //             sticker.height = Math.max(10, y - sticker.y); // 确保高度不小于10
    //         } else {
    //             // 移动贴纸
    //             sticker.x = x - sticker.width / 2; // 使贴纸中心跟随鼠标
    //             sticker.y = y - sticker.height / 2;
    //         }
    //         setElements([...elements]); // 更新状态以触发重绘
    //     }
    // };
    //
    // const handleMouseUp = (e) => {
    //     const canvas = canvasRef.current;
    //     if (!canvas.contains(e.target)) {
    //         return; // 只有当鼠标在画布上释放时才执行
    //     }
    //
    //     setIsMouseDown(false); // 重置鼠标按下状态
    //     const updatedElements = elements.map(el => ({ ...el, isDragging: false, isResizing: false }));
    //     setElements(updatedElements); // 更新状态以停止拖动
    //     canvasRef.current.style.cursor = 'default'; // 恢复默认光标
    //     // redrawCanvas(); // 在鼠标释放时重新绘制画布
    // };

    // 检查是否点击了调整手柄
    // const checkIfResizing = (sticker, x, y) => {
    //     const handleSize = 10; // 手柄的大小
    //     return (
    //         (x >= sticker.x + sticker.width - handleSize / 2 && x <= sticker.x + sticker.width + handleSize / 2 &&
    //          y >= sticker.y + sticker.height - handleSize / 2 && y <= sticker.y + sticker.height + handleSize / 2) // 右下角
    //     );
    // };

    
    // 绘制调整手柄
    const drawResizeHandles = (ctx, element) => {
        const handleSize = 10; // 手柄的大小
        ctx.fillStyle = 'red'; // 手柄颜色
    
        // 左上角手柄
        // ctx.fillRect(element.x - handleSize / 2, element.y - handleSize / 2, handleSize, handleSize);
        // // 右上角手柄
        // ctx.fillRect(element.x + element.width - handleSize / 2, element.y - handleSize / 2, handleSize, handleSize);
        // // 左下角手柄
        // ctx.fillRect(element.x - handleSize / 2, element.y + element.height - handleSize / 2, handleSize, handleSize);
        // // 右下角手柄
        ctx.fillRect(element.x + element.width - handleSize / 2, element.y + element.height - handleSize / 2, handleSize, handleSize);
    };


    return (
        <div className={styles.drawingBoard}>
            <div className={styles.toolbarContainer}>
                <ToolBar
                     currentTool={currentTool}
                     onToolChange={setCurrentTool}
                     onUndo={undo}
                     onRedo={redo}
                     canUndo={historyIndex > 0}
                     canRedo={historyIndex < history.length - 1}
                     onClear={clearCanvas}
                     onSave={saveCanvas}
                     onShowColorPicker={() => togglePanel('colorPicker')}
                     onShowBrushSize={() => togglePanel('brushSize')}
                     onShowStickers={() => togglePanel('stickers')}
                     isMobile={isMobile}
                />
                
                {panels.colorPicker && (
                    <ColorPicker
                        currentColor={color}
                        onColorChange={setColor}
                        onClose={() => togglePanel('colorPicker')}
                    />
                )}

                {panels.brushSize && (
                    <BrushSizeSelector
                        currentSize={lineWidth}
                        onSizeChange={setLineWidth}
                        onClose={() => togglePanel('brushSize')}
                    />
                )}

                {panels.stickers && (
                    <StickerPanel
                        onStickerSelect={addSticker} // 确保这里调用 addSticker
                        onClose={() => togglePanel('stickers')}
                    />
                )}
            </div>
            
            <div className={styles.canvasWrapper}>
                <canvas
                    ref={canvasRef}
                    className={styles.drawingCanvas}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseOut={stopDrawing}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                    onTouchCancel={stopDrawing}
                />
            </div>
        </div>
    );
};

export default DrawingBoard; 