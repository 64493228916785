import React, {} from 'react';
import { 
    BsPencil, 
    BsEraser, 
    BsImage, 
    BsPalette,
    BsArrowCounterclockwise,
    BsArrowClockwise,
    BsTrash,
    BsDownload, BsBrush
} from 'react-icons/bs';
import styles from './ToolBar.module.css';

export const ToolBar = ({
    currentTool,
    onToolChange,
    onUndo,
    onRedo,
    canUndo,
    canRedo,
    onClear,
    onSave,
    onShowColorPicker,
    onShowBrushSize,
    onShowStickers,
    isMobile
}) => {


    return (
        <div className={styles.toolbar}>

            <div className={styles.toolGroup}>
                <button
                    className={`${styles.toolButton} ${currentTool === 'brush' ? styles.active : ''}`}
                    onClick={() => onToolChange('brush')}
                    title="画笔"
                >
                    <BsPencil/>
                    {!isMobile && <span>画笔</span>}
                </button>

                <button
                    className={`${styles.toolButton} ${currentTool === 'eraser' ? styles.active : ''}`}
                    onClick={() => onToolChange('eraser')}
                    title="橡皮擦"
                >
                    <BsEraser/>
                    {!isMobile && <span>橡皮擦</span>}
                </button>
            </div>

            <div className={styles.toolGroup}>
                <button
                    className={styles.toolButton}
                    onClick={onShowColorPicker}
                    title="颜色"
                >
                    <BsPalette/>
                    {!isMobile && <span>颜色</span>}
                </button>

                <button
                    className={styles.toolButton}
                    onClick={onShowBrushSize}
                    title="笔触"
                >
                    <BsBrush/>
                    {!isMobile && <span>笔触</span>}
                </button>
            </div>

            <div className={styles.toolGroup}>
                <button
                    className={styles.toolButton}
                    onClick={onUndo}
                    disabled={!canUndo}
                    title="撤销"
                >
                    <BsArrowCounterclockwise/>
                    {!isMobile && <span>撤销</span>}
                </button>

                <button
                    className={styles.toolButton}
                    onClick={onRedo}
                    disabled={!canRedo}
                    title="重做"
                >
                    <BsArrowClockwise/>
                    {!isMobile && <span>重做</span>}
                </button>
            </div>

            <div className={styles.toolGroup}>
                {/* <button
                    className={styles.toolButton}
                    onClick={onShowStickers}
                    title="贴纸"
                >
                    <BsImage />
                    {!isMobile && <span>贴纸</span>}
                </button> */}
                {/* 手机端不显示贴纸按钮 */}
                {!isMobile && (
                    <button
                        className={styles.toolButton}
                        onClick={onShowStickers}
                        title="贴纸"
                    >
                        <BsImage/>
                        <span>贴纸</span>
                    </button>
                )}


                <button
                    className={styles.toolButton}
                    onClick={onClear}
                    title="清空"
                >
                    <BsTrash/>
                    {!isMobile && <span>清空</span>}
                </button>

                <button
                    className={styles.toolButton}
                    onClick={onSave}
                    title="保存"
                >
                    <BsDownload/>
                    {!isMobile && <span>保存</span>}
                </button>
            </div>
        </div>
    );
};