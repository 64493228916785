import apiClient from './axiosConfig';

export const trackAnonymousVisit = async () => {
    try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
        const ipAddress = data.ip; // 获取 IP 地址

        // 发送 IP 地址到后端
        await apiClient.post('/api/trackVisit', { ipAddress });
    } catch (error) {
        console.error('Error tracking anonymous visit:', error);
    }
}; 