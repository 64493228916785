import React, {useState, useEffect} from 'react';
import imageStyle from './Mountain.module.css';
import apiClient from '../../../utils/axiosConfig';
import sunIcon from '../../../static/sun.png';
import moonIcon from '../../../static/moon.png';
import refresh from '../../../static/refresh.png'
import loadingIcon from '../../../static/loading.gif';
import { isMobile } from 'react-device-detect';

const Mountain = () => {
    const [image, setImage] = useState({ url: isMobile ? 'https://bing.img.run/1366x768.php' : 'https://bing.img.run/m.php', alt: '1080P高清' });    const [isDay, setIsDay] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isToggling, setIsToggling] = useState(false);

    const fetchImage = async (isDay, isMobile) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await apiClient.post('/api/mountain', {
                isDay: isDay,
                isMobile: isMobile,
            });
            const data = response.data.data; // 只取第一张图片
            setImage(data);
        } catch (error) {
            console.error('Error fetching images:', error);
            setImage({ url: isMobile ? 'https://bing.img.run/rand_m.php' : 'https://bing.img.run/rand.php', alt: '1080P高清' }); // 设置默认图像
        } finally {
            setLoading(false);
            setTimeout(() => {
                setIsRefreshing(false);
                setIsToggling(false);
            }, 2000); // 1秒延迟
        }
    };

    useEffect(() => {
        fetchImage(isDay, isMobile)
        // eslint-disable-next-line
    }, [isDay]);

    const toggleTheme = async () => {
        if (isToggling) return;
        setIsToggling(true);
        try {
            setIsDay(!isDay);
            await fetchImage(!isDay, isMobile);
        } catch (error) {
            console.error('Error toggling theme:', error);
        } finally {
            setLoading(false);
            setTimeout(() => {
                setIsToggling(false);
            }, 2000); // 1秒延迟
        }
    };

    const refreshImage = async () => {
        if (isRefreshing) return;
        setIsRefreshing(true);
        try {
            await fetchImage(isDay, isMobile);
        } catch (error) {
            console.error('Error refreshing image:', error);
        } finally {
            setLoading(false);
            setTimeout(() => {
                setIsRefreshing(false);
            }, 2000); // 1秒延迟
        }
    };

    return (
        <div className={`${imageStyle.imageGallery} ${isDay ? '' : imageStyle.nightMode}`}>
            <div className={imageStyle.buttonContainer}>
                <button
                    className={`${imageStyle.refreshButton} ${isRefreshing ? imageStyle.disabled : ''}`}
                    onClick={refreshImage}
                    disabled={isRefreshing}
                >
                    {isRefreshing ? <img src={loadingIcon} alt='Loading...'/> : <img src={refresh} alt='Refresh'/>}
                </button>
                <button
                    className={`${imageStyle.toggleButton} ${isToggling ? imageStyle.disabled : ''}`}
                    onClick={toggleTheme}
                    disabled={isToggling}
                >
                    {isToggling ? <img src={loadingIcon} alt='Loading...'/> :
                        <img src={isDay ? sunIcon : moonIcon} alt={isDay ? 'Sun' : 'Moon'}/>}
                </button>
            </div>
            {loading || !image ? (
                <div className={imageStyle.loading}>
                    <img src={loadingIcon} alt='Loading...'/>
                </div>
            ) : (
                <div className={imageStyle.imageItem}>
                    <img
                        className={imageStyle.lazyImage}
                        src={image.url}
                        alt={image.alt}
                    />
                </div>
            )}
        </div>
    );
}

export default Mountain;
