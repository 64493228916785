// wechat/EmojiSelector.js
import React from 'react';
import style from './EmojiSelector.module.css'

const EmojiSelector = ({ onEmojiSelect }) => {
  const emojis = [
    { alt: '微笑', src: 'path/to/smile.png' },
    { alt: '大笑', src: 'path/to/laugh.png' },
    // 更多表情包
  ];

  return (
    <div className={style.emojiselector}>
      {emojis.map((emoji, index) => (
        <img
          key={index}
          src={emoji.src}
          alt={emoji.alt}
          className={style.emoji}
          onClick={() => onEmojiSelect(`![${emoji.alt}](${emoji.src})`)}
        />
      ))}
    </div>
  );
};

export default EmojiSelector;
