// src/axiosConfig.js
import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_ServerURL, // 替换为你的 API 地址
    // baseURL: 'http://localhost:8001',
    timeout: 10000, // 请求超时设置
    headers: {
        'Content-Type': 'application/json',
    },
});

export default apiClient;
