// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import IntlProviderWrapper from './config/IntlProviderWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <IntlProviderWrapper>
            <App />
        </IntlProviderWrapper>
    </React.StrictMode>,
);

// 如果你想开始测量应用的性能，请传递一个函数
// 以记录结果（例如：reportWebVitals(console.log)）
// 或发送到分析端点。了解更多：https://bit.ly/CRA-vitals
reportWebVitals();