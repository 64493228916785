// src/components/PixelAvatarsModal.js
import React, {useEffect, useState} from 'react';
import pixelAvatarsModalStyles from './PixelAvatarsModal.module.css';
import apiClient from '../../../utils/axiosConfig'; // 导入配置

const PixelAvatarsModal = ({ show, onClose }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true); // 控制加载状态
    const [hoveredIndex, setHoveredIndex] = useState(null); // 标记当前悬停的图片索引

    useEffect(() => {

        const fetchUsers = async () => {
            try {

                const response = await apiClient.get('/api/users');
                setUsers(response.data.data.users.map(user => ({
                    ...user,
                    // avatar: `/api/users/avatar/${user.id}` // 访问本地图片
                })));
                setLoading(false); // 设置加载完成
            } catch (error) {
                console.error('Error fetching users:', error);
                alert('Failed to fetch users. Please try again later.');
            }
        };

        fetchUsers();

    }, []);

    if (!show) return null;


    return (
        <div className={pixelAvatarsModalStyles.modalOverlay}>
            <div className={pixelAvatarsModalStyles.modalContent}>
                <button className={pixelAvatarsModalStyles.closeButton} onClick={onClose}>
                    X
                </button>
                <div className={pixelAvatarsModalStyles.avatarsContainer}>
                    {loading ? (
                        <p>Loading avatars...</p>
                    ) : (
                        users.map((user, index) => (
                            <img
                                key={index}
                                src={user.avatarUrl}
                                alt={`Avatar ${index + 1}`}
                                className={pixelAvatarsModalStyles.avatar}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            />
                        ))
                    )}
                    {hoveredIndex !== null && (
                        <div className={pixelAvatarsModalStyles.zoomedImageContainer}>
                            <img
                                src={users[hoveredIndex].avatarUrl}
                                alt={`Zoomed Avatar ${hoveredIndex + 1}`}
                                className={pixelAvatarsModalStyles.zoomedImage}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PixelAvatarsModal;