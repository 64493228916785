// Footer.js
import React from 'react';
import footerStyle from './Footer.module.css'; // 确保路径正确
import { useIntl } from 'react-intl';
import MusicPlayer from '../page/music/MusicPlayer'; // 确保路径正确

const Footer = () => {
    const intl = useIntl();

    return (
        <footer className={footerStyle.footer}>
            <div className={footerStyle.musicContainer}>
                <MusicPlayer /> {/* Music 组件 */}
            </div>
            <p className={footerStyle.footerContent}>
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" >
                    {intl.formatMessage({id: 'footer.p'})}
                </a>
            </p>
        </footer>
);
};

export default Footer;
