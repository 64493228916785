import React, { useState } from 'react';
import styles from './ColorPicker.module.css';

const PRESET_COLORS = [
    '#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF',
    '#FFFF00', '#FF00FF', '#00FFFF', '#FFA500', '#800080',
    '#008000', '#800000', '#008080', '#000080', '#FFC0CB',
    '#A52A2A', '#FFD700', '#40E0D0', '#FF6B6B', '#4CAF50'
];

export const ColorPicker = ({ currentColor, onColorChange, onClose }) => {
    const [customColor, setCustomColor] = useState(currentColor);

    const handleColorChange = (color) => {
        onColorChange(color);
    };

    return (
        <div className={styles.colorPicker}>
            <div className={styles.header}>
                <h3>选择颜色</h3>
                <button onClick={onClose}>×</button>
            </div>
            
            <div className={styles.presetColors}>
                {PRESET_COLORS.map((color, index) => (
                    <button
                        key={index}
                        className={`${styles.colorButton} ${color === currentColor ? styles.active : ''}`}
                        style={{ backgroundColor: color }}
                        onClick={() => handleColorChange(color)}
                        title={color}
                    />
                ))}
            </div>

            <div className={styles.customColor}>
                <span>自定义颜色</span>
                <input
                    type="color"
                    value={customColor}
                    onChange={(e) => {
                        setCustomColor(e.target.value);
                        handleColorChange(e.target.value);
                    }}
                    className={styles.colorInput}
                />
            </div>
        </div>
    );
};