   // IntlProviderWrapper.js
   import React from 'react';
   import { IntlProvider } from 'react-intl';
   import { messages } from './messages'; // 修改导入方式

   const locale = 'en'; // 根据实际情况设置语言

   const IntlProviderWrapper = ({ children }) => (
       <IntlProvider locale={locale} messages={messages}>
          {children}
       </IntlProvider>
   );

   export default IntlProviderWrapper;