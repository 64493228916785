import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from './StickerPanel.module.css';

// 示例贴纸数据，按分类组织
const STICKER_CATEGORIES = {
    emoji: [
        // { id: 'emoji1', url: '/stickers/emoji/smile.png', name: '笑脸' },
        // { id: 'emoji2', url: '/stickers/emoji/heart.png', name: '爱心' },
        // { id: 'emoji3', url: '/stickers/emoji/star.png', name: '星星' },
        { id: 'emoji1', url: 'https://via.placeholder.com/100/ffeb3b/000000?text=😊', name: '笑脸' , width: 100, height: 100 },
        { id: 'emoji2', url: 'https://via.placeholder.com/100/e91e63/ffffff?text=❤', name: '爱心' , width: 100, height: 100 },
        { id: 'emoji3', url: 'https://via.placeholder.com/100/ffc107/000000?text=⭐', name: '星星' , width: 100, height: 100 },
        // 添加更多表情贴纸
    ],
    animals: [
        { id: 'animal1', url: '/stickers/animals/Elephant.png', name: '青蛙' , width: 100, height: 100 },
        { id: 'animal2', url: '/stickers/animals/Frog.png', name: '大象', width: 100, height: 100 },
        { id: 'animal3', url: '/stickers/animals/Wolf.png', name: '狼' , width: 100, height: 100 },
        // 添加更多动物贴纸
    ],
    decorative: [
        { id: 'deco1', url: '/stickers/decorative/flower.png', name: '花朵', width: 100, height: 100 },
        { id: 'deco2', url: '/stickers/decorative/crown.png', name: '皇冠' , width: 100, height: 100 },
        { id: 'deco3', url: '/stickers/decorative/ribbon.png', name: '丝带' , width: 100, height: 100 },
        // 添加更多装饰贴纸
    ],
    custom: [] // 新增自定义分类
};

export const StickerPanel = ({ onStickerSelect, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('emoji');
    const [customStickers, setCustomStickers] = useState([]); // 存储自定义上传的贴纸


    const handleDragStart = (sticker) => (e) => {
        e.dataTransfer.setData('sticker', JSON.stringify(sticker));
    };

    const getAllFilteredStickers = () => {
        if (!searchTerm) return [];
        return Object.values(STICKER_CATEGORIES)
            .flat()
            .filter(sticker =>
                sticker.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const stickerData = {
                    id: `custom_${customStickers.length + 1}`, // 生成唯一 ID
                    url: reader.result,
                    name: file.name,
                    width: 100, // 默认宽度
                    height: 100 // 默认高度
                };
                setCustomStickers(prev => [...prev, stickerData]); // 添加到自定义贴纸列表
                STICKER_CATEGORIES.custom.push(stickerData); // 将自定义贴纸添加到自定义分类
            };
            reader.readAsDataURL(file);
        }
    };

    // 监听自定义事件以处理贴纸拖动
    useEffect(() => {

        // const handleStickerDragStart = (e) => {
        //     const stickerData = e.detail;
        //     console.log("Sticker dragged:", stickerData);
        // };
    
        // document.addEventListener('stickerDragStart', handleStickerDragStart);
    
        // return () => {
        //     document.removeEventListener('stickerDragStart', handleStickerDragStart);
        // };
    }, []);

    return (
        <div className={styles.stickerPanel}>
            <div className={styles.header}>
                <h3>选择贴纸</h3>
                <button className={styles.closeButton} onClick={onClose}>×</button>
            </div>

            <div className={styles.searchBar}>
                <div className={styles.searchUploadContainer}>
                    <input
                        type="text"
                        placeholder="搜索贴纸..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className={styles.searchInput}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }} // 隐藏文件输入
                        id="uploadStickerInput"
                    />
                    <label htmlFor="uploadStickerInput" className={`${styles.uploadButton} ${styles.visibleUploadButton}`}>
                        上传贴纸
                    </label>
                </div>
            </div>

            {searchTerm ? (
                <div className={styles.searchResults}>
                    <div className={styles.stickerGrid}>
                        {getAllFilteredStickers().map((sticker) => (
                            <div
                                key={sticker.id}
                                className={styles.stickerItem}
                                draggable
                                onDragStart={handleDragStart(sticker)}
                                // onTouchStart={handleTouchStart(sticker)} // 添加触摸事件处理
                                title={sticker.name}
                            >
                                <img src={sticker.url} alt={sticker.name}  />
                                {/* <div
                                    className={styles.resizeHandle}
                                    onMouseDown={(e) => handleResize(sticker, e)} // 处理调整大小
                                /> */}
                            </div>
                        ))}
                    </div>
                    {getAllFilteredStickers().length === 0 && (
                        <div className={styles.noResults}>
                            没有找到匹配的贴纸
                        </div>
                    )}
                </div>
            ) : (
                <Tabs
                    selectedIndex={Object.keys(STICKER_CATEGORIES).indexOf(selectedCategory)}
                    onSelect={(index) => setSelectedCategory(Object.keys(STICKER_CATEGORIES)[index])}
                >
                    <TabList className={styles.tabList}>
                        <Tab className={styles.tab}>表情</Tab>
                        <Tab className={styles.tab}>动物</Tab>
                        <Tab className={styles.tab}>装饰</Tab>
                        <Tab className={styles.tab}>自定义</Tab>
                    </TabList>

                    {Object.keys(STICKER_CATEGORIES).map((category) => (
                        <TabPanel key={category}>
                            <div className={styles.stickerGrid}>
                                {STICKER_CATEGORIES[category].map((sticker) => (
                                    <div
                                        key={sticker.id}
                                        className={styles.stickerItem}
                                        draggable
                                        onDragStart={handleDragStart(sticker)}
                                        title={sticker.name}
                                    >
                                        <img src={sticker.url} alt={sticker.name}  />
                                        {/* <div
                                            className={styles.resizeHandle}
                                            onMouseDown={(e) => handleResize(sticker, e)} // 处理调整大小
                                        /> */}
                                    </div>
                                ))}
                            </div>
                        </TabPanel>
                    ))}
                </Tabs>
            )}
        </div>
    );
};