// components/ChatWindow.js
import React, { useState, useEffect } from 'react';
import style from './ChatWindow.module.css'

const ChatWindow = () => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const socket = new WebSocket('ws://api.techhive.space/chat');
    const userName = 'My'; // 替换为当前用户的名称

    useEffect(() => {
    // socket.onmessage = (event) => {
    //   const newMessage = JSON.parse(event.data);
    //   setMessages((prevMessages) => [...prevMessages, newMessage]);
    // };
    //
    // return () => {
    //   socket.close();
    // };
        const messages = [
            { sender: 'Alice', content: '你好，今天天气真好！', isSelf: false },
            { sender: 'My', content: '是啊，适合出去走走。', isSelf: true },
            { sender: 'Bob', content: '你们去哪玩？', isSelf: false },
            { sender: 'My', content: '我们打算去公园。', isSelf: true },
            { sender: 'Alice', content: '听起来不错，一起去吧！', isSelf: false },
            { sender: 'My', content: '好的，那我们下午见！', isSelf: true },
        ];
        setMessages(messages);
    }, []);

    const sendMessage = () => {
        if (message) {
            socket.send(JSON.stringify({ content: message }));
            setMessages((prevMessages) => [
                ...prevMessages,
                { content: message, sender: userName, isSelf: true }
            ]);
            setMessage('');
        }
    };

    return (
        <div className={style.chatWindow}>
              <div className={style.messageList}>
                  {messages.map((msg, index) => (
                      <div
                          key={index}
                          className={`${style.message} ${msg.isSelf ? style.selfMessage : style.otherMessage}`}
                      >
                          <div className={style.contentWrapper}>
                              {!msg.isSelf && (
                                  <>
                                      <span className={style.sender}>{msg.sender}</span>
                                      <span className={style.content}>{msg.content}</span>
                                  </>
                              )}
                              {msg.isSelf && (
                                  <>
                                      <span className={style.content}>{msg.content}</span>
                                      <span className={style.sender}>{msg.sender}</span>
                                  </>
                              )}
                          </div>
                      </div>
                  ))}
              </div>
            <div className={style.inputContainer}>
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="输入消息"
                  />
                  <button onClick={sendMessage}>发送</button>
              </div>
        </div>
    );
};

export default ChatWindow;
