// src/components/Register.js
import React, {useEffect, useState } from 'react';
import apiClient from '../../../utils/axiosConfig'; // 导入配置
import registerStyles from "./Register.module.css";
import loadingIcon from '../../../static/loading.gif'; // 导入加载图标


const Register = ({show, onClose, onRegisterSuccess }) => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [avatarFile, setAvatarFile] = useState(null); // 新增状态变量用于保存文件
    const [avatarUrl, setAvatarUrl] = useState(null); // 用于显示预览图
    const [errors, setErrors] = useState({}); // 用于存储验证错误
    const [loading, setLoading] = useState(false); // 添加 loading 状态
    // const [showQRCode, setShowQRCode] = useState(false); // 控制二维码显示
    // const [qrCodeUrl, setQRCodeUrl] = useState(''); // 存储二维码 URL

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({}); // 重置所有错误
        // 前端校验
        const validationErrors = {};

        if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
            validationErrors.phoneNumber = 'Invalid phone number format.';
        }

        if (!userName || userName.length < 3 || userName.length > 20) {
            validationErrors.userName = 'Username must be between 3 and 20 characters.';
        }
        
        if (!avatarFile) {
            validationErrors.avatar = 'Please upload an avatar.';
        } else if (!isValidImage(avatarFile)) {
            validationErrors.avatar = 'Invalid image format or size.';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setLoading(true); // 开始加载
        const formData = new FormData();
        formData.append('phoneNumber', phoneNumber);
        formData.append('userName', userName);
        // const userInfo = {
        //     phoneNumber,
        //     userName
        // };
        // formData.append('userInfo', JSON.stringify(userInfo)); // 添加 userInfo 字段
        if (avatarFile) {
            formData.append('avatarBase64', avatarFile);
        }
        // console.log(formData)
        
        try {
            await apiClient.post('/api/users/create', formData);
            const newUser = [{formData}];
            // console.log(newUser);
            onRegisterSuccess(newUser)
            onClose(); // 关闭窗口
        } catch (error) {
            console.error('Error registering user:', error);
            setErrors({ submit: 'Failed to register. Please try again.' });
        } finally {
            setLoading(false); // 结束加载
        }
    };

    const handleAvatarChange = (e) => {
        const files = e.target.files;
        if (files.length > 0) {
            const file = files[0];
            if (isValidImage(file)) {
                setAvatarFile(file);
                setAvatarUrl(URL.createObjectURL(file));
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64String = e.target.result;
                    setAvatarFile(base64String);
                };
                reader.readAsDataURL(file);
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    avatar: 'Invalid image format or size.'
                }));
            }

        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                avatar: 'Invalid image format or size.'
            }));
        }
    };

    const isValidPhoneNumber = (phoneNumber) => {
        // 假设手机号码格式为 11 位数字
        const regex = /^\d{11}$/;
        return regex.test(phoneNumber);
    };

    const isValidImage = (file) => {
        // 检查文件类型和大小
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
        const maxSize = 2 * 1024 * 1024; // 2MB
        return allowedTypes.includes(file.type) && file.size <= maxSize;
    };


    // const handleWeChatRegister = async () => {
    //     // 显示二维码
    //     try {
    //         const response = await apiClient.get('/api/wechat/qr-code');
    //         setQRCodeUrl(response.data.qrCodeUrl);
    //         setShowQRCode(true);
    //     } catch (error) {
    //         console.error('Error fetching QR code:', error);
    //     }
    // };

    // const closeQRCodeModal = () => {
    //     setShowQRCode(false);
    // };

    useEffect(() => {
        // if (!show) {
        //     setQRCodeUrl(null);
        // }
    }, [show]);

    if (!show) return null;

    return (
        <div className={registerStyles.registerFormContainer}>
            <button className={registerStyles.closeButton} onClick={onClose}>
                X
            </button>

            <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={registerStyles.inputField + (errors.phoneNumber ? ' error' : '')}
            />
            {errors.phoneNumber && <div className={registerStyles.error}>{errors.phoneNumber}</div>}
            <input
                type="text"
                placeholder="UserName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                className={registerStyles.inputField + (errors.userName ? ' error' : '')}
            />
            {errors.userName && <div className={registerStyles.error}>{errors.userName}</div>}
            <label htmlFor="file-input" className={registerStyles.customFileInput}>
                Upload Avatar
                <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    onChange={handleAvatarChange}
                    className={registerStyles.fileInput}
                />
            </label>
            {avatarUrl && (
                <img src={avatarUrl} alt="Avatar Preview" className={registerStyles.avatarPreview}/>
            )}
            {errors.avatar && <div className={registerStyles.error}>{errors.avatar}</div>}
            <div className={registerStyles.buttonRow}>
                <button 
                    type="submit" 
                    onClick={handleSubmit} 
                    className={registerStyles.submitButton}
                    disabled={loading}
                >
                    {loading ? (
                        <img src={loadingIcon} alt="Loading..." className={registerStyles.loadingIcon}/>
                    ) : (
                        'Register'
                    )}
                </button>
                {/* <button type="button" onClick={handleWeChatRegister} className={registerStyles.weChatButton}>
                    [ ||| ]  Scan
                </button> */}
            </div>
            {/* {showQRCode && (
                <div className={registerStyles.qrCodeModal}>
                    <div className={registerStyles.qrCodeContent}>
                        <button className={registerStyles.closeButton} onClick={closeQRCodeModal}>
                            X
                        </button>
                        <img src={qrCodeUrl} alt="QR Code" className={registerStyles.qrCodeImage}/>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default Register;