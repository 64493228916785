// components/AddFriendForm.js
import React, { useState } from 'react';
import style from './AddFriendForm.module.css'

const AddFriendForm = () => {
  const [username, setUsername] = useState('');

  const handleAddFriend = async (event) => {
    event.preventDefault();
    const response = await fetch('/api/friends/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username }),
    });

    if (response.ok) {
      alert('添加成功！');
    } else {
      alert('添加失败，请重试。');
    }
  };

  return (
      <form onSubmit={handleAddFriend} className={style.addFriendForm}>
          <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="搜索好友"
              className={style.searchInput}
          />
          <button type="submit" className={style.addButton}>+</button>
      </form>
  );
};

export default AddFriendForm;
