// MusicPlayer.js
import React, { useState, useRef, useEffect } from 'react';
import musicPlayer from './MusicPlayer.module.css';
import needle from '../../../static/needle.png'; // 引用针的图标
import cd from '../../../static/cd.jpg';
import apiClient from "../../../utils/axiosConfig";


const MusicPlayer = () => {
    const [songs, setSongs] = useState([]);
    const [currentSong, setCurrentSong] = useState(songs[0]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false); //播放器状态
    const [isNeedleVisible, setIsNeedleVisible] = useState(false); // 新增状态
    // const [isLyricsExpanded, setIsLyricsExpanded] = useState(false); //歌词状态
    const audioRef = useRef(null);
    const playPauseSong = () => {
        setIsPlaying(!isPlaying);
        if (isPlaying) {
            audioRef.current.pause();
            setIsNeedleVisible(false); // 针隐藏
        } else {
            audioRef.current.play();
            setIsNeedleVisible(true); // 针显示
        }
    };

    const nextSong = () => {
        const currentIndex = songs.findIndex(song => song.id === currentSong.id);
        const nextIndex = (currentIndex + 1) % songs.length;
        setCurrentSong(songs[nextIndex]);
        audioRef.current.src = songs[nextIndex].src;
        audioRef.current.load();
        audioRef.current.oncanplay = () => {
            audioRef.current.play();
            setIsPlaying(true);
        };
    };

    const prevSong = () => {
        const currentIndex = songs.findIndex(song => song.id === currentSong.id);
        const prevIndex = (currentIndex - 1 + songs.length) % songs.length;
        setCurrentSong(songs[prevIndex]);
        audioRef.current.src = songs[prevIndex].src;
        audioRef.current.load();
        audioRef.current.oncanplay = () => {
            audioRef.current.play();
            setIsPlaying(true);
        };
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    // const toggleLyrics = () => {
    //     setIsLyricsExpanded(!isLyricsExpanded);
    // };
    useEffect(() => {
        // 等待页面 DOM 加载完成
        if (document.readyState === 'complete') {
            fetchMusicList();
            console.log("load music")
        } else {
            window.addEventListener('load', fetchMusicList);
        }

        return () => {
            window.removeEventListener('load', fetchMusicList);
        };
    }, []);
    const fetchMusicList  = async () => {
        try {
            const response = await apiClient.get('/api/musicList');
            // const data = [ { id: 1, title: '屋顶 (Live) - 周杰伦_宿涵', src: '/music/屋顶 (Live) - 周杰伦_宿涵.mp3' },
            //     { id: 2, title: '因为爱情 (Live) - 周杰伦_那英', src: '/music/因为爱情 (Live) - 周杰伦_那英.mp3' }]
            const data = response.data.data;
            setSongs(data);
            // 设置初始歌曲
            setCurrentSong(data[0]);
            // 更新歌曲源
            // audioRef.current.src = data[0].src;
            // audioRef.current.load();
        } catch (error) {
            console.error('Error fetching music list:', error);
        }
    };

    useEffect(() => {
        if (isPlaying && audioRef.current) {
            audioRef.current.play();
            setIsNeedleVisible(true); // 播放时显示针
        } else if (audioRef.current) {
            audioRef.current.pause();
            setIsNeedleVisible(false); // 暂停时隐藏针
        }

    }, [isPlaying]);

    return (
        <div className={musicPlayer.mPlayer } onClick={toggleExpand}>
            <div >
                <img className={musicPlayer.cdIcon + " " +(isPlaying ? musicPlayer.rotating : musicPlayer.static)} src={cd} alt="cd"></img>
                <img className={musicPlayer.cdNeedle + " " + (isNeedleVisible ? musicPlayer.visible : musicPlayer.invisible)} src={needle}
                     alt="Needle"/>
            </div>

            {isExpanded && (
                <div className={musicPlayer.expandedPlayer}>
                    {/*<div className={musicPlayer.songBackground}></div>*/}
                    <div className={musicPlayer.expandedPlayerControls}>
                        <button className={musicPlayer.prevButton} onClick={(e) => {
                            e.stopPropagation();
                            prevSong()
                        }} />
                        <button className={isPlaying ? musicPlayer.pauseButton : musicPlayer.playButton} onClick={(e) => {
                            e.stopPropagation();
                            playPauseSong()
                        }} />
                        <button className={musicPlayer.nextButton}  onClick={(e) => {
                            e.stopPropagation();
                            nextSong()
                        }} />
                        {/*<button onClick={(e) => {*/}
                        {/*    e.stopPropagation();*/}
                        {/*    toggleLyrics()*/}
                        {/*}}>Lyrics*/}
                        {/*</button>*/}
                    </div>

                    <div className={musicPlayer.glassAudioPlayer}>
                        <audio ref={audioRef} src={currentSong?.url} controls controlsList="nodownload nofullscreen noremoteplayback noplaybackrate">
                            <source src={currentSong?.url} type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                </div>
            )}
            {/*{isExpanded && isLyricsExpanded && (*/}
            {/*    <div className={musicPlayer.lyricsSwitch}>*/}
            {/*        <div className={musicPlayer.lyrics}>*/}
            {/*            /!* 歌词内容 *!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

export default MusicPlayer;
