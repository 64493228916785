// src/components/HYXJ.js
import React, { useState, useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';
import hyxjStyles from './HYXJ.module.css';
import apiClient from '../../../utils/axiosConfig';
import loadingIcon from '../../../static/loading.gif'; // 导入加载图标

function HYXJ() {
    const [inputWord, setInputWord] = useState('');
    const [htmlContent, setHtmlContent] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const iframeRef = useRef(null);

    useEffect(() => {
        const loadDemoHtml = async () => {
            const response = await fetch('/static/demo.html'); // Adjust the path as necessary
            const text = await response.text();
            setHtmlContent(text); // Set the loaded HTML content
        };

        loadDemoHtml();
    }, []);

    const handleConfirm = async () => {
        setIsLoading(true); 
        try {
            const response = await apiClient.post('/api/hyxj', {
                word: inputWord,
            });
            const taskId = response.data.data.taskId; 
            // console.log('Task ID:', taskId);
            if (taskId) {
                await pollForResult(taskId);
            } else {
                console.error('Task ID is undefined');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const pollForResult = async (taskId) => {
        const maxAttempts = 10;
        const interval = 2000;
        let attempts = 0; 
        while (attempts < maxAttempts) {
            try {
                const response = await apiClient.post(`/api/hyxj/query?taskId=${taskId}`);
                const data = await response.data.data;
                if (data && data.html) {
                    setHtmlContent(data.html);
                    setIsLoading(false);
                    return; // 成功后退出
                } else if (data.status === "processing") {
                    // Optionally log or handle the processing status
                    console.log(data.message); // Log the processing message
                }
            } catch (error) {
                if (attempts === maxAttempts - 1) {
                    console.error('Polling error:', error);
                }
            }
            attempts++; 
            await new Promise(resolve => setTimeout(resolve, interval));
        }
        setIsLoading(false); // Reset loading state if max attempts reached without success
        console.warn('Max polling attempts reached without a valid response.'); // Log a warning
    };

    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    const download = async () => {
        const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        if (iframeDocument) {
            const canvas = await html2canvas(iframeDocument.body, {
                useCORS: true, // 允许跨域
                allowTaint: true, // 允许污染
            });
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'downloaded_image.png';
            link.click();
        }
    };

    return (
        <div className={hyxjStyles.container}>
            <div className={hyxjStyles.inputGroup}>
                <input
                    className={hyxjStyles.inputBox}
                    value={inputWord}
                    onChange={(e) => setInputWord(e.target.value)}
                    placeholder="说吧,他们有用哪个词来忽悠你了？"
                    id="inputWord"
                    name="inputWord"
                />
                <button onClick={handleConfirm} disabled={isLoading} className={hyxjStyles.buttonBox}>
                    {isLoading ? (
                        <img src={loadingIcon} alt="Loading..." className={hyxjStyles.loadingIcon} />
                    ) : (
                        '一阵见血'
                    )}
                </button>
            </div>
            {htmlContent && (
                <div className={hyxjStyles.fullscreenButton} onClick={toggleFullscreen}>
                    点击可全屏查看
                </div>
            )}
            <div className={hyxjStyles.card} >
                <iframe
                    ref={iframeRef}
                    srcDoc={htmlContent} // Use srcDoc to render the HTML content
                    title="Content Display" // 添加 title 属性
                    className={`${hyxjStyles.iframeView} ${isFullscreen ? hyxjStyles.fullscreen : ''}`}
                    sandbox="allow-same-origin allow-scripts"
                />
            </div>
            {isFullscreen && <div className={hyxjStyles.fullscreenOverlay} onClick={toggleFullscreen} />}
            {htmlContent && (
                <div className={hyxjStyles.download} onClick={download}>
                    下载
                </div>
            )}
        </div>
    );
}

export default HYXJ;