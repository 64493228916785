// components/FriendList.js
import React, { useState, useEffect } from 'react';
import style from './FriendList.module.css'

const FriendList =  ({ onFriendClick, selectedFriend }) => {
    const [friends, setFriends] = useState([]);

    useEffect(() => {
      fetchFriends();
    }, []);

    const fetchFriends = async () => {
      // const response = await fetch('/api/friends');
      // const data = await response.json();
      const data = [
        { id: 1, name: '张三' },
        { id: 2, name: '李四' },
        { id: 3, name: '王五' },
        { id: 4, name: '赵六' },
        { id: 5, name: '孙七' }
      ];
      setFriends(data);
    };

    return (
      <div className={style.friendList}>
        <ul>
          {friends.map(friend => (
              <li
                  key={friend.id}
                  className={`${style.friendItem} ${friend.id === selectedFriend?.id ? style.selected : ''}`}
                  onClick={() => onFriendClick(friend)}
              >
                {friend.name}
              </li>
          ))}
        </ul>
      </div>
    );
};

export default FriendList;
