// ChatMain.js
import React, { useState } from 'react';
import FriendList from './FriendList';
import AddFriendForm from './AddFriendForm';
import ChatWindow from './ChatWindow';
import VideoCall from './VideoCall';
import EmojiSelector from './EmojiSelector';
import chatStyle from './ChatMain.module.css';

function ChatMain() {


    const [selectedFriend, setSelectedFriend] = useState(null);

    const handleFriendClick = (friend) => {
        setSelectedFriend(friend);
    };

    const handleEmojiSelect = (emoji) => {
    // 处理表情包选择
    };

    return (
        <div className={chatStyle.chatMain}>
            <div className={chatStyle.functionBar}>
                <button>功能1</button>
                <button>功能2</button>
                <button>功能3</button>
            </div>
            <div className={chatStyle.sidebar}>
                <AddFriendForm/>
                <FriendList onFriendClick={handleFriendClick} selectedFriend={selectedFriend} />
            </div>
            <div className={chatStyle.mainContent}>
                <div className={chatStyle.chatWindowContainer}>
                    {selectedFriend ? (
                        <ChatWindow friend={selectedFriend}>
                            <EmojiSelector onEmojiSelect={handleEmojiSelect}/>
                        </ChatWindow>
                    ) : (
                        <p>请选择一个好友开始聊天</p>
                    )}
                </div>
            </div>
            {/*<VideoCall />*/}
        </div>
    );
}

export default ChatMain;
