// src/App.js
import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home'; // 引入 Home 组件
import HYXJ from './components/page/hyxj/HYXJ'; // 引入 HYXJ 组件
import DrawingBoard from './components/page/drawing/DrawingBoard';
import { trackAnonymousVisit } from './utils/trackVisit';
import Mountain from './components/page/mountain/Mountain';
import ChatMain from "./components/wechat/ChatMain";

function App() {
    useEffect(() => {
        // 页面加载时记录匿名用户的访问
        trackAnonymousVisit();
    }, []);
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} /> 
                <Route path="/hyxj" element={<HYXJ />} />
                <Route path='/drawing' element={<DrawingBoard />} />
                <Route path='/mountain' element={<Mountain />} />
                <Route path='/wechat' element={<ChatMain />} />
                <Route path="*" element={<Navigate to="/" replace />} /> {/* 确保这条路由在最后 */}
            </Routes>
        </BrowserRouter>
    );
}

export default App;