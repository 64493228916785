import React from 'react';
import styles from './BrushSizeSelector.module.css';

const BRUSH_SIZES = [
    { size: 2, label: '细' },
    { size: 5, label: '中' },
    { size: 10, label: '粗' },
    { size: 20, label: '特粗' }
];

export const BrushSizeSelector = ({ currentSize, onSizeChange, onClose }) => {
    return (
        <div className={styles.brushSelector}>
            <div className={styles.header}>
                <h3>笔触大小</h3>
                <button onClick={onClose}>×</button>
            </div>

            <div className={styles.sizeOptions}>
                {BRUSH_SIZES.map(({ size, label }) => (
                    <div
                        key={size}
                        className={`${styles.sizeOption} ${currentSize === size ? styles.active : ''}`}
                        onClick={() => onSizeChange(size)}
                    >
                        <div 
                            className={styles.sizePreview}
                            style={{ width: size, height: size }}
                        />
                        <span>{label}</span>
                    </div>
                ))}
            </div>

            <div className={styles.customSize}>
                <span>自定义大小: {currentSize}px</span>
                <input
                    type="range"
                    min="1"
                    max="50"
                    value={currentSize}
                    onChange={(e) => onSizeChange(Number(e.target.value))}
                    className={styles.sizeSlider}
                />
            </div>
        </div>
    );
};