// src/components/Content.js
import React, { useState, useEffect } from 'react';
import styles from './Content.module.css';

const Content = () => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [weather, setWeather] = useState(null);
    const [location, setLocation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        
        const fetchWeatherData = async (latitude, longitude) => {
            try {
                 // 检查 AMap 是否已加载
                 if (!window.AMap) {
                    console.error('AMap not loaded');
                    // setError('地图服务未加载');
                    setLoading(false);
                    return;
                }
                // 使用高德地图逆地理编码获取城市信息
                const citySearch = new window.AMap.CitySearch();
                citySearch.getLocalCity(async (status, result) => {
                    if (status === 'complete' && result.info === 'OK') {
                        setLocation(result);
                    } else {
                        console.error('Failed to get city:', result);
                    }
                });
                
                // 使用和风天气 API 获取天气信息
                const weatherResponse = await fetch(
                    `https://devapi.qweather.com/v7/weather/now?key=557967cf7b8d41bf949eb018244418da&location=${longitude},${latitude}`
                );
                const weatherData = await weatherResponse.json();

                // setLocation(locationData.regeocode.addressComponent);
                setWeather(weatherData.now);
                setLoading(false);
            } catch (err) {
                // setError('Failed to fetch weather data');
                setLoading(false);
            }
        };

        // 获取用户位置
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchWeatherData(latitude, longitude);
                },
                (err) => {
                    // setError('Failed to get location');
                    setLoading(false);
                }
            );
        } else {
            setError('Geolocation is not supported by your browser');
            setLoading(false);
        }

        // 每秒更新一次时间
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // 组件卸载时清除定时器
        return () => clearInterval(timer);
    }, []);

    // 格式化时间的函数
    const formatTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}年${month}月${day}日 ${hours}:${minutes}:${seconds}`;
    };

    return (
        <div className={styles.contentContainer}>
            <div className={styles.content}>
                <h2 className={styles.title}>愿做这浩瀚宇宙的一粒尘埃</h2>
                <div className={styles.clock}>{formatTime(currentTime)}</div>
                <div className={styles.weather}>
                    {loading ? (
                        <span>Loading weather...</span>
                    ) : error ? (
                        <span>{error}</span>
                    ) : weather ? (
                        <div>
                            <span>{location ? location.city : ''} | </span>
                            <span>{weather.temp}°C | </span>
                            <span>{weather.text} |</span>
                            <span>{weather.windDir} | </span>
                            <span>{weather.windScale}级 | </span>
                            <span>{weather.windSpeed}公里/小时</span>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Content;