// src/components/UniverseBackground.js
import React, {useEffect, useRef, useState} from 'react';
import './UniverseBackground.module.css';
import apiClient from "../utils/axiosConfig"; // 确保导入 App.css

const UniverseBackground = ({ registeredUsers }) => {
    const canvasRef = useRef(null);
    const [anonymousUserCount, setAnonymousUserCount] = useState('');

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) {
            console.error('Canvas element not found.');
            return;
        }

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.error('Failed to get 2D context.');
            return;
        }

        // 初始化匿名用户
        const anonymousUsers = [];
        // 发送请求获取匿名用户的数量
        const fetchAnonymousUserCount = () =>{
            apiClient.get('/api/visit/anonymousUserCount').then(
                response => {
                    if (response.data && response.data.data.total_visits) {
                        const parsedTotalVisits = parseInt(response.data.data.total_visits, 10);
                        setAnonymousUserCount(parsedTotalVisits);
                    } else {
                        console.error("Invalid response data: total_visits is missing or not valid");
                    }
                }
            ).catch(error => {
                setAnonymousUserCount(10);
                console.error("Error fetching anonymous user count:", error);
            })
        }
        // 页面加载时获取匿名用户的数量
        fetchAnonymousUserCount();
        // console.log("anonymousUserCount:" + anonymousUserCount)


        for (let i = 0; i < registeredUsers.length; i++) {
            anonymousUsers.push({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                vx: (Math.random() - 0.5) * 2, // 横向速度
                vy: (Math.random() - 0.5) * 2, // 纵向速度
                color: `rgba(255, 255, 255, 1)`, // 随机颜色和透明度
                radius: 10, // 初始半径
                maxRadius: 10, // 最大半径
                opacity: 0.1, // 初始透明度
                maxOpacity: 1, // 最大透明度
                blurRadius: 0 // 初始模糊半径
            });
        }
        for (let i = 0; i < anonymousUserCount; i++) {
            anonymousUsers.push({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                vx: (Math.random() - 0.5) * 2, // 横向速度
                vy: (Math.random() - 0.5) * 2, // 纵向速度
                color: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.random()})`, // 随机颜色和透明度
                radius: 1, // 初始半径
                maxRadius: 4, // 最大半径
                opacity: 0.1, // 初始透明度
                maxOpacity: 1, // 最大透明度
                blurRadius: 0 // 初始模糊半径
            });
        }

        // 更新用户的属性
        const updateUserProperties = (user, deltaTime, width, height) => {
            // 更新位置
            user.x += user.vx ;
            user.y += user.vy ;

            // 更新半径
            if (user.radius < user.maxRadius) {
                user.radius += deltaTime * 0.1; // 半径增长速度
            }

            // 更新透明度
            if (user.opacity < user.maxOpacity) {
                user.opacity += deltaTime * 0.01; // 透明度增长速度
            }

            // 更新模糊半径
            if (user.blurRadius < 5) {
                user.blurRadius += deltaTime * 0.01; // 模糊半径增长速度
            }

            // 边界条件处理
            if (user.x < 0 || user.x > width || user.y < 0 || user.y > height) {
                user.x = Math.random() * width;
                user.y = Math.random() * height;
                user.vx = (Math.random() - 0.5) * 2;
                user.vy = (Math.random() - 0.5) * 2;
                user.radius = 1;
                user.opacity = 0.1;
                user.blurRadius = 0;
            }
        }


        let animationFrameId;
        const drawUser = (ctx, user, color) => {
            ctx.fillStyle = color;
            ctx.beginPath();
            ctx.arc(user.x, user.y, user.radius, 0, Math.PI * 2);
            ctx.fill();

            // 应用模糊滤镜
            ctx.filter = `blur(${user.blurRadius}px)`;
            ctx.beginPath();
            ctx.arc(user.x, user.y, user.radius + 2, 0, Math.PI * 2);
            ctx.fill();
            ctx.filter = 'none'; // 清除滤镜
        }


        const animate = () => {

            const width = window.innerWidth;
            const height = window.innerHeight;
            canvas.width = width;
            canvas.height = height;

            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, width, height);

            const deltaTime = 1 / 60; // 假设每帧时间为 1/60 秒

            // 绘制匿名用户
            anonymousUsers.forEach(user => {
                // drawAndMoveUser(ctx, user, user.color, 2, width, height);
                updateUserProperties(user, deltaTime, width, height);
                drawUser(ctx, user, user.color);
            });

            // 绘制注册用户
            // registeredUsers.forEach(user => {
                // drawAndMoveUser(ctx, user, 'rgba(255, 255, 255, 1)', 5, width, height);
                // updateUserProperties(user, deltaTime, width, height);
                // drawUser(ctx, user, user.color);
            // });

            animationFrameId = requestAnimationFrame(animate);

        };

        const handleResize = () => {
            cancelAnimationFrame(animationFrameId)
            animate();
        };

        animate();

        window.addEventListener('resize', handleResize);

        // 返回清除函数
        return () => {
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationFrameId)
        };
    }, [registeredUsers, anonymousUserCount]);

    return <canvas ref={canvasRef} className="backCanvas"/>;
};

export default UniverseBackground;